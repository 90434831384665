import apiClient from '@/services/axios'

export async function getData(page, perPage, columns, filter, order) {
  return apiClient.post('/ortb/lib/sites', {
    Page: page,
    PerPage: perPage,
    Columns: columns,
    Order: order.filter(el => columns.includes(el.Column)),
    Filter: filter,
  })
    .then(response => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch(err => console.log(err))
}
